import React, { Fragment } from 'react';
import Center from '../styleguide/Center';
import Progresser from './Progresser';
import { VerticalSpacer } from '../styleguide/Spacer';
import SuccessForm from './SuccessForm';
import FormSent from './FormSent';
import { HorizontalSpacer } from '../styleguide/Spacer';
import ContentSizer from '../styleguide/ContentSizer';
import Section from '../styleguide/Section';
import Card from '../styleguide/Card';
import Block from '../styleguide/Block';
import { ButtonExternal } from '../styleguide/Button';
import Text from '../styleguide/Text';

function Result({ answers, sent, setSentForm, quizData, accept }) {
  let failed = false;

  let hasFailed = [];

  for (let i = 0; i < answers.length; i++) {
    const item = answers[i];
    if (item === 'false') hasFailed.push(item);
  }

  if (hasFailed.length > 0) failed = true;

  const failedTitle = 'Takk for at du deltok!';
  const successTitle = 'Det var en god start!';

  if (failed) {
    window.history.pushState(null, null, 'x');
  } else {
    window.history.pushState(null, null, 'success');
  }

  const successSubtitle =
    'Ønsker du å motta mer informasjon om vårt givende fosterhjemsarbeid? Legg igjen din kontaktinformasjon nedenfor, så hører du fra oss.';

  return (
    <VerticalSpacer>
      <HorizontalSpacer>
        <ContentSizer>
          <Section>
            <Card>
              <Block tighter>
                {sent ? (
                  <FormSent accept={accept} />
                ) : (
                  <Fragment>
                    <Center>
                      <Progresser />
                    </Center>
                    <Text
                      variant="subtitle"
                      color="primary"
                      gutterTop="xlarge"
                      gutterBottom="large"
                      align="center"
                    >
                      {failed ? failedTitle : successTitle}
                    </Text>
                    {!failed && (
                      <Text align="center" gutterBottom="large" width="60%">
                        {successSubtitle}
                      </Text>
                    )}

                    {failed && (
                      <Fragment>
                        <Text align="center" gutterBottom="large" width="60%">
                          Basert på svarene dine ser det dessverre ikke ut til
                          at du oppfyller våre kriterier for å kunne bli et
                          Stendi familie- eller beredskapshjem. Du kan lese mer
                          om fosterhjemsarbeidet vårt på{' '}
                          <a
                            target="_blank"
                            href="https://www.stendi.no/barnevern/fosterhjem"
                            rel="noopener noreferrer"
                          >
                            vår nettside.
                          </a>
                        </Text>
                        <Center>
                          <ButtonExternal href="https://stendi.no">
                            Tilbake til Stendi familiehjem
                          </ButtonExternal>
                        </Center>
                      </Fragment>
                    )}
                    {!failed && (
                      <Fragment>
                        <SuccessForm
                          quizData={quizData}
                          answers={answers}
                          setSentForm={setSentForm}
                        />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Block>
            </Card>
          </Section>
        </ContentSizer>
      </HorizontalSpacer>
    </VerticalSpacer>
  );
}

export default Result;
