import { Container } from 'unstated';
import quizData from '../utils/quiz';

export default class QuizContainer extends Container {
  state = {
    quizData,
    started: false,
    currentStep: 0,
    completed: false,
    answers: [],
    sent: false,
    accept: false,
  };

  nextStep = (answer) => {
    const { currentStep, quizData } = this.state;

    if (currentStep === quizData.length - 1) {
      this.completeQuiz();

      this.setState((prevState) => ({
        currentStep: prevState.currentStep + 1,
        answers: [...prevState.answers, answer],
      }));

      // returning void here because
      // we don't want to trigger the
      // currentStep state transition
      // again.
      return;
    }

    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
      answers: [...prevState.answers, answer],
    }));
  };

  startQuiz = () => {
    this.setState({
      started: true,
    });
  };

  completeQuiz = () => {
    this.setState({
      completed: true,
    });
  };

  setSentForm = (accept) => {
    this.setState({
      sent: true,
      accept: accept || false,
    });
  };
}
