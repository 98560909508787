import React, { Component } from 'react';
import {
  Form,
  Input,
  Label,
  Checkbox,
  Select,
  Field,
} from '../styleguide/Form';
import Button from '../styleguide/Button';
import receivers from '../utils/receivers_new';
import Search from './Downshift';
import Text from '../styleguide/Text';
import config from '../utils/config';

class SuccessForm extends Component {
  state = {
    email: '',
    region: '',
    commune: '',
    county: '',
    name: '',
    phone: '',
    accept: undefined,
    consentBasic: undefined,
    consentEmail: undefined,
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  handleSelect = (region, commune, county) => {
    this.setState({
      region: region,
      commune: commune,
      county: county,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const {
      email,
      region,
      commune,
      county,
      phone,
      name,
      accept,
      consentBasic,
      consentEmail,
    } = this.state;
    const { setSentForm } = this.props;
    const url = config.profilerUrl;

    const data = {
      email,
      name,
      phone,
      [config.characteristics.region]: region,
      [config.characteristics.accept]: accept,
      [config.characteristics.county]: county,
      [config.characteristics.commune]: commune,
      'consent:basic': consentBasic,
      'consent:email': consentEmail,
    };

    const response = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      setSentForm(accept);
    }
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Field>
          <Label htmlFor="name">
            <span>
              Navn <span className="required">*</span>
            </span>
          </Label>
          <Input
            type="text"
            label="Navn"
            placeholder="Skriv inn ditt fulle navn"
            name="name"
            id="name"
            required
            withLabelSpace
            onChange={this.handleInputChange}
          />
        </Field>

        <Field>
          <Label htmlFor="phone">
            <span>
              Telefonnummer{' '}
              {this.state.accept ? <span className="required">*</span> : ''}
            </span>
          </Label>
          <Input
            type="text"
            label="Telefonnummer"
            placeholder="Skriv inn ditt telefonnummer"
            name="phone"
            id="phone"
            required={this.state.accept}
            withLabelSpace
            onChange={this.handleInputChange}
          />
        </Field>
        <Field>
          <Label htmlFor="email">
            <span>
              E-post <span className="required">*</span>
            </span>
          </Label>
          <Input
            type="email"
            label="E-post"
            placeholder="Skriv inn din e-postadresse"
            name="email"
            id="email"
            required
            withLabelSpace
            onChange={this.handleInputChange}
          />
        </Field>
        <Field>
          <Label htmlFor="county">
            <span>
              Kommune <span className="required">*</span>
            </span>
          </Label>
          <Search handleSelect={this.handleSelect} items={receivers} />
        </Field>
        <Field>
          <Label>
            <span>
              Ønsker du at en fagkonsulent skal ringe deg for en uforpliktende
              samtale?
            </span>
          </Label>
          <Select
            value={
              typeof this.state.accept === 'undefined'
                ? ''
                : this.state.accept
                ? 'true'
                : 'false'
            }
            onChange={(e) => {
              this.setState({
                accept: e.target.value === 'true',
              });
            }}
            withLabelSpace
            required
          >
            <option value="">Vennligst velg…</option>
            <option value="true">Ja</option>
            <option value="false">Nei</option>
          </Select>
        </Field>

        <Field>
          <Checkbox
            required={true}
            onChange={(e) => {
              this.setState({
                consentBasic: e.target.checked,
              });
            }}
          >
            Jeg godtar at Stendi oppbevarer de oppgitte personopplysningene i
            tråd med{' '}
            <a
              target="_blank"
              href="https://res.cloudinary.com/rubics/image/upload/v1580221629/Personvernerklaering_Stendi_bzxm7t.pdf"
              rel="noopener noreferrer"
            >
              personvernerklæringen
            </a>
            , og at Stendi kan kontakte meg per telefon og/eller e-post.{' '}
            <span className="required">*</span>
          </Checkbox>
        </Field>
        <Field>
          <Checkbox
            onChange={(e) => {
              this.setState({
                consentEmail: e.target.checked,
              });
            }}
          >
            Jeg ønsker å motta relevante nyhetsbrev fra Stendi Familiehjem
          </Checkbox>
        </Field>
        <Field>
          <Text>
            <Text element="span" color="primary">
              *
            </Text>
            Feltet må fylles ut.
          </Text>
        </Field>
        <Field>
          <Button type="submit" fullWidth>
            Send
          </Button>
        </Field>
      </Form>
    );
  }
}

export default SuccessForm;
