import image1 from './images/01.png';
import image2 from './images/02.png';
import image3 from './images/03.png';
import image4 from './images/04.png';
import image5 from './images/05.png';

export default [
  {
    question: 'Er du omsorgsfull?',
    step: 1,
    image: image1,
  },
  {
    question: 'Har du ledig soverom?',
    step: 2,
    image: image2,
  },
  {
    question: 'Har du stabil økonomi?',
    step: 3,
    image: image3,
  },
  {
    question: 'Har du god vandel?',
    step: 4,
    image: image4,
  },
  {
    question:
      'Er du villig til å frikjøpes fra jobb for å ivareta et barn som trenger det?',
    step: 5,
    image: image5,
  },
];
