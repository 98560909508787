import React from 'react';
import Center from '../styleguide/Center';
import ContentWrapper from '../styleguide/ContentWrapper';
import { ButtonExternal } from '../styleguide/Button';
import Text from '../styleguide/Text';

function FormSent({ failed, accept }) {
  window.history.pushState(null, null, 'sent');

  return (
    <ContentWrapper>
      <Center>
        <Text
          variant="subtitle"
          color="primary"
          gutterBottom="large"
          align="center"
        >
          {failed
            ? 'Takk for din interesse'
            : accept
            ? 'Takk - din henvendelse er mottatt'
            : 'Takk for din interesse, du vil nå motta våre nyhetsbrev'}
        </Text>

        <Center>
          <ButtonExternal
            href="https://stendi.no/barnevern/fosterhjem"
            target="_blank"
            rel="noreferrer noopener"
          >
            Tilbake til informasjon om fosterhjem
          </ButtonExternal>
        </Center>
      </Center>
    </ContentWrapper>
  );
}

export default FormSent;
